@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* ======================================================= Custom colors ====================================================== */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins',sans-serif;
}

:root{
  --bgcolor:#f1f5fd;
  --primary-color:#384c97;
  --text-color:#828286;
  --main-text-color:#333333;
  --shadow-color: #b7caf7;
  --view-btn-color:#42a9ed;
  --update-btn-color:#ed982f;
  --delete-btn-color:#db2c25;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

a{
  text-decoration: none;
}

html {
  background-color: var(--bgcolor);
}

html::-webkit-scrollbar{
  width:0.45vw;
}

html::-webkit-scrollbar-thumb{
  background-color: var(--primary-color);
  border-radius:1.5vw;
}

html::-webkit-scrollbar-track{
  background-color: var(--bgcolor);
}

/* ======================================================================= Log in =============================================================================== */
#login {
  background-color: var(--bgcolor);
}

.login{
  min-height: 70vh;
  width:35rem !important;
  max-width: 40rem;
  margin-top:25vh;
}

.login .login-btn{
  margin-top:1.5rem;
  color:#fff;
  background-color: var(--primary-color);
  border-radius: 0.6rem!important;
  border: 0.1rem solid var(--primary-color);
}

.login .login-btn:hover{
  border: 0.1rem solid var(--primary-color) !important;
  color:#fff !important;
  background-color: var(--primary-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.login .card{
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
  border-radius: 0.9rem;
  padding: 0.5rem;
}

.login a{
  text-decoration: none;
  color: var(--primary-color);
}

.login a:hover{
  text-decoration: none;
  color: var(--primary-color);
}

/* ================================================================== Forgot Password ============================================================================ */
#forgotPassword {
  background-color: var(--bgcolor);
}

.forgotPassword{
  min-height: 70vh;
  width:35rem !important;
  max-width: 40rem;
  margin-top:25vh;
}

.forgotPassword .card{
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
  border-radius: 0.9rem;
  padding: 0.5rem;
}

.forgotPassword .reset-pwd-btn{
  margin-top:1.5rem;
  color:#fff;
  background-color: var(--primary-color);
  border-radius: 0.6rem!important;
  border: 0.1rem solid var(--primary-color);
}

.forgotPassword .reset-pwd-btn:hover{
  border: 0.1rem solid var(--primary-color) !important;
  color:#fff !important;
  background-color: var(--primary-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.forgotPassword a{
  text-decoration: none;
  color: var(--primary-color);
}

.forgotPassword a:hover{
  text-decoration: none;
  color: var(--primary-color);
}

/* =========================================================== Admin Dashboard =========================================================================== */
#dashboard{
  background-color: var(--bgcolor);
  display: flex;
}

/* ----------------------------------------------------------- Sidebar ---------------------------------------------------------------------------------- */
.sidebar {
  flex:1;
  background:var(--primary-color);
  color:#fff;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
  min-height:100vh;
  max-width:18vw;
}

.sidebar .top{
  height:5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar .top .logo{
  padding-top:2vh;
  font-size:2rem;
  font-weight:600;
}

.sidebar hr{
  height:0;
  border:0.5px solid var(--shadow-color);
}

.sidebar .center ul{
  list-style:none;
  margin:0;
  padding:0;
}

.sidebar .center ul li {
  display: flex;
  align-items: center;
  padding:0.8rem 0.8rem 0.8rem 2rem;  
  cursor: pointer;
}

.sidebar .center ul li a{
  text-decoration: none !important;
  color: inherit;
}

.sidebar .center ul a{
  text-decoration: none !important;
  color: inherit;
}

.sidebar .center ul li:hover{
  background-color: #fff;
  color:var(--primary-color);
  border-radius: 0.4rem;
}

.sidebar .center ul li span{
  font-size: 1.1rem;
  font-weight: 400;
}

.sidebar .center ul li .materialIcon{
  font-size:1.8rem;
  margin-right: 1rem;
}

.sidebar .bottom{
  bottom:0;
  display: flex;
  align-items: center;
  position:fixed;
  padding-left: 1.5rem;
  padding-bottom: 1rem;
}

.sidebar .bottom a{
  text-decoration: none;
  cursor: pointer;
  color:inherit;
  padding-left: 0.2rem;
}

/* ------------------------------------------------------------- Main Dashboard Content ----------------------------------------------------------------------- */
.dashboard{
  flex:4;
}

/* ------------------------------------------------------------- Widgets ---------------------------------------------------------------------------- */
.widgets{
  display: flex;
  padding: 2rem;
  gap:1.8rem;
}

.widget{
  display: flex;
  flex:1;
  padding:1rem;
  justify-content: space-between;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
  border-radius: 0.5rem;
  background-color: #fff;
  height: 10rem;
}

.widget .left, .right{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.widget .left .title{
  font-weight: 650;
  font-size: 1.45rem;
  text-transform: capitalize;
}

.widget .left .counter{
  font-size: 2.6rem;
  font-weight:400;
}

.widget .left .link{
  font-size: 0.95rem;
  border-bottom: 1px solid gray;
  width:max-content;
  cursor:pointer;
}

.widget .right .materialIcon{
  font-size:4rem;
  justify-content: center;
  align-self:center;
  margin-right:15%;
  margin-top: 50%;
}

/* ------------------------------------------------------------ Charts -------------------------------------------------------------- */
.charts{
  display: flex;
  padding: 0.05rem 2rem;
  flex:4;
  gap:1.8rem;
}


/* =========================================================== Highlights =========================================================== */
.highlights{
  background-color: var(--bgcolor);
  display: flex;
  margin:0;
  padding:0;
}

.allHighlights{
  display: flex;
  flex:4;
  background-color: #fff;
}

.allHighlights .highlightsTitle{
  width:100%;
  font-size:2.2rem;
  margin-bottom:1rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.allHighlights .highlightsTitle .addHighlightBtn{
  text-decoration: none;
  color:green;
  font-size:1rem;
  font-weight: 400;
  border:1px solid green;
  padding: 5px;
  border-radius: 5px;
  cursor:pointer;
  display: flex;
}

.allHighlights .highlightsTitle .addHighlightBtn .materialIcon{
  font-size: 1rem;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
  align-items: center;
  justify-content: center;
}

.allHighlights .highlightsTitle .addHighlightBtn:hover{
  color:#fff;
  background-color:green;
}

.allHighlights .highlightTable{
  width:75vw;
  margin: 2rem;
}

.allHighlights .cellAction{
  display: flex;
  align-items: center;
  gap:1.5rem;
  width: 20rem;
  padding:0.7rem 0rem;
}

.allHighlights .cellAction .viewButton{
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--view-btn-color);
  border: 1px solid var(--view-btn-color);
  cursor:pointer;  
}

.allHighlights .cellAction .viewButton:hover{
  border: 0.1rem solid var(--view-btn-color) !important;
  color:#fff !important;
  background-color: var(--view-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.allHighlights .cellAction .updateButton{
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--update-btn-color);
  border: 1px solid var(--update-btn-color);
  cursor:pointer;  
}

.allHighlights .cellAction .updateButton:hover{
  border: 0.1rem solid var(--update-btn-color) !important;
  color:#fff !important;
  background-color: var(--update-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.allHighlights .cellAction .deleteButton{
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--delete-btn-color);
  border: 1px solid var(--delete-btn-color);
  cursor:pointer;
}

.allHighlights .cellAction .deleteButton:hover{
  border: 0.1rem solid var(--delete-btn-color) !important;
  color:#fff !important;
  background-color: var(--delete-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

/* ------------------------------------- Add Highlight ----------------------------------------------------- */
.addHighlight{
  width:100vw;
  display: flex;
  background-color: var(--bgcolor);
}

.addHighlight .addHighlightContainer{
  margin-top: 3rem;
  margin-left:2rem;
  padding: 1.5rem;
  background-color:#fff;
  width: 70vw;
  height:fit-content;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.addHighlightContainer form .formInput{
  width:45%;
  padding:1rem;
}

.addHighlightContainer form .formInput label{
  padding-bottom: 0.4rem;
}

.addHighlightContainer form .formInput input, textarea{
  width: 100%;
  padding:0.3rem;
  border-radius: 3px;
  border: 0.1rem solid #b5b3b3;
}

.addHighlightContainer form button{
  margin: 1rem;
  padding: 0.6rem;
  width:10vw;
  border-radius: 0.5rem;
  cursor:pointer;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.addHighlightContainer form button:hover{
  color:#fff;
  background-color: var(--primary-color);
}

/* ------------------------------------- Edit Highlight ----------------------------------------------------- */
.editHighlight{
  width:100vw;
  display: flex;
  background-color: var(--bgcolor);
}

.editHighlight .editHighlightContainer{
  margin-top: 3rem;
  margin-left:2rem;
  padding: 1.5rem;
  background-color:#fff;
  width: 70vw;
  height:fit-content;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.editHighlightContainer form .formInput{
  width:45%;
  padding:1rem;
}

.editHighlightContainer form .formInput label{
  padding-bottom: 0.4rem;
}

.editHighlightContainer form .formInput input, textarea{
  width: 100%;
  padding:0.3rem;
  border-radius: 3px;
  border: 0.1rem solid #b5b3b3;
}

.editHighlightContainer form button{
  margin: 1rem;
  padding: 0.6rem;
  width:10vw;
  border-radius: 0.5rem;
  cursor:pointer;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.editHighlightContainer form button:hover{
  color:#fff;
  background-color: var(--primary-color);
}

/* =========================================================== Publications =========================================================== */
.publications{
  background-color: var(--bgcolor);
  display: flex;
  margin:0;
  padding:0;
}

.allPublications{
  display: flex;
  flex:4;
  background-color: #fff;
}

.allPublications .publicationsTitle{
  width:100%;
  font-size:2.2rem;
  margin-bottom:1rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.allPublications .publicationsTitle .addPublicationBtn{
  text-decoration: none;
  color:green;
  font-size:1rem;
  font-weight: 400;
  border:1px solid green;
  padding: 5px;
  border-radius: 5px;
  cursor:pointer;
  display: flex;
}

.allPublications .publicationsTitle .addPublicationBtn .materialIcon{
  font-size: 1rem;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
  align-items: center;
  justify-content: center;
}

.allPublications .publicationsTitle .addPublicationsBtn:hover{
  color:#fff;
  background-color:green;
}

.allPublications .publicationsTable{
  width:75vw;
  margin: 2rem;
}

.allPublications .cellAction{
  display: flex;
  align-items: center;
  gap:1.5rem;
  width: 20rem;
  padding:0.7rem 0rem;
}

.allPublications .cellAction .viewButton{
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--view-btn-color);
  border: 1px solid var(--view-btn-color);
  cursor:pointer;  
}

.allPublications .cellAction .viewButton:hover{
  border: 0.1rem solid var(--view-btn-color) !important;
  color:#fff !important;
  background-color: var(--view-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.allPublications .cellAction .updateButton{
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--update-btn-color);
  border: 1px solid var(--update-btn-color);
  cursor:pointer;  
}

.allPublications .cellAction .updateButton:hover{
  border: 0.1rem solid var(--update-btn-color) !important;
  color:#fff !important;
  background-color: var(--update-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.allPublications .cellAction .deleteButton{
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--delete-btn-color);
  border: 1px solid var(--delete-btn-color);
  cursor:pointer;
}

.allPublications .cellAction .deleteButton:hover{
  border: 0.1rem solid var(--delete-btn-color) !important;
  color:#fff !important;
  background-color: var(--delete-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

/* ----------------------------------- View Publication ------------------------------------------------------ */
.viewPublication{
  display: flex;
  flex:4;
  background-color: var(--bgcolor);
}

.viewPublication .publicationDetails{
  margin-top: 2rem;
  margin-left: 2rem;
  padding: 1.5rem;
  width:75vw;
  height:fit-content;
  background-color:white !important;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.viewPublication .publicationDetails .strong{
  width:25%;
  font-weight:500;
  font-size:1.3rem;
  padding-top:0.5rem;
}

.viewPublication .publicationDetails .content{
  font-size:1.1rem;
  text-align: justify;
}

.viewPublication .publicationDetails .actionBtns{
  display: flex;
  align-items: center;
  gap:1.5rem;
  width: 30%;
  padding-top: 1rem;
}

.viewPublication .publicationDetails .backButton{
  padding: 0.7rem;
  width:fit-content;
  border-radius: 5px;
  color: var(--view-btn-color);
  border: 1px solid var(--view-btn-color);
  cursor:pointer;
}

.viewPublication .publicationDetails .backButton:hover{
  border: 0.1rem solid var(--view-btn-color) !important;
  color:#fff !important;
  background-color: var(--view-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.viewPublication .publicationDetails .updateButton{
  padding: 0.7rem;
  border-radius: 5px;
  width:fit-content;
  color: var(--update-btn-color);
  border: 1px solid var(--update-btn-color);
  cursor:pointer;  
}

.viewPublication .publicationDetails .updateButton:hover{
  border: 0.1rem solid var(--update-btn-color) !important;
  color:#fff !important;
  background-color: var(--update-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

/* ------------------------------------- Add Publication ----------------------------------------------------- */
.addPublication{
  width:99vw;
  display: flex;
  background-color: var(--bgcolor);
}

.addPublication .addPublicationContainer{
  margin-top: 1.5rem;
  margin-left:2rem;
  padding: 1.5rem;
  background-color:#fff;
  width: 70vw;
  height:fit-content;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.addPublicationContainer form .formInput{
  width:90%;
  padding:1rem;
}

.addPublicationContainer form .formInput label{
  padding-bottom: 0.4rem;
}

.addPublicationContainer form .formInput input, textarea{
  width: 100%;
  padding:0.3rem;
  border-radius: 3px;
  border: 0.1rem solid #b5b3b3;
}

.addPublicationContainer form button{
  margin: 1rem;
  padding: 0.6rem;
  width:10vw;
  border-radius: 0.5rem;
  cursor:pointer;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.addPublicationContainer form button:hover{
  color:#fff;
  background-color: var(--primary-color);
}

/* ------------------------------------- Edit Publication ----------------------------------------------------- */
.editPublication{
  width:99vw;
  display: flex;
  background-color: var(--bgcolor);
}

.editPublication .editPublicationContainer{
  margin-top: 1.5rem;
  margin-left:2rem;
  padding: 1.5rem;
  background-color:#fff;
  width: 70vw;
  height:fit-content;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.editPublicationContainer form .formInput{
  width:90%;
  padding:1rem;
}

.editPublicationContainer form .formInput label{
  padding-bottom: 0.4rem;
}

.editPublicationContainer form .formInput input, textarea{
  width: 100%;
  padding:0.3rem;
  border-radius: 3px;
  border: 0.1rem solid #b5b3b3;
}

.editPublicationContainer form button{
  margin: 1rem;
  padding: 0.6rem;
  width:10vw;
  border-radius: 0.5rem;
  cursor:pointer;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.editPublicationContainer form button:hover{
  color:#fff;
  background-color: var(--primary-color);
}

/* =========================================================== Members =========================================================== */
.members{
  background-color: var(--bgcolor);
  display: flex;
  margin:0;
  padding:0;
}

.allMembers{
  display: flex;
  flex:4;
  background-color: #fff;
}

.allMembers .membersTitle{
  width:100%;
  font-size:2.2rem;
  margin-bottom:1rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.allMembers .membersTitle .addMemberBtn{
  text-decoration: none;
  color:green;
  font-size:1rem;
  font-weight: 400;
  border:1px solid green;
  padding: 5px;
  border-radius: 5px;
  cursor:pointer;
  display: flex;
}

.allMembers .membersTitle .addMemberBtn .materialIcon{
  font-size: 1rem;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
  align-items: center;
  justify-content: center;
}

.allMembers .membersTitle .addMemberBtn:hover{
  color:#fff;
  background-color:green;
}

.allMembers .membersTable{
  width:75vw;
  margin: 2rem;
}

.allMembers .membersTable .cellWithImg {
  display: flex;
  align-items: center;
  margin: 0.6rem 0rem;
}

.allMembers .membersTable .cellWithImg .cellImg {
  width: 50px;
  height: 50px;
  border-radius: 45%;
  object-fit: cover;
  margin-right: 20px;
}

.allMembers .cellAction{
  display: flex;
  align-items: center;
  gap:1.5rem;
  width: 20rem;
  padding:0.7rem 0rem;
}

.allMembers .cellAction .viewButton{
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--view-btn-color);
  border: 1px solid var(--view-btn-color);
  cursor:pointer;  
}

.allMembers .cellAction .viewButton:hover{
  border: 0.1rem solid var(--view-btn-color) !important;
  color:#fff !important;
  background-color: var(--view-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.allMembers .cellAction .updateButton{
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--update-btn-color);
  border: 1px solid var(--update-btn-color);
  cursor:pointer;  
}

.allMembers .cellAction .updateButton:hover{
  border: 0.1rem solid var(--update-btn-color) !important;
  color:#fff !important;
  background-color: var(--update-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.allMembers .cellAction .deleteButton{
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--delete-btn-color);
  border: 1px solid var(--delete-btn-color);
  cursor:pointer;
}

.allMembers .cellAction .deleteButton:hover{
  border: 0.1rem solid var(--delete-btn-color) !important;
  color:#fff !important;
  background-color: var(--delete-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

/* ------------------------------------- Add Member ----------------------------------------------------- */
.addMember{
  width:99vw;
  display: flex;
  background-color: var(--bgcolor);
}

.addMember .addMemberContainer{
  margin-top: 1.5rem;
  margin-left:2rem;
  padding: 1.5rem;
  background-color:#fff;
  width: 70vw;
  height:fit-content;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.addMemberContainer form .formInput{
  width:90%;
  padding:1rem;
}

.addMemberContainer form .formInput label{
  padding-bottom: 0.4rem;
}

.addMemberContainer form .formInput input, select{
  width: 100%;
  padding:0.3rem;
  border-radius: 3px;
  border: 0.1rem solid #b5b3b3;
}

.addMemberContainer form button{
  margin: 1rem;
  padding: 0.6rem;
  width:10vw;
  border-radius: 0.5rem;
  cursor:pointer;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.addMemberContainer form button:hover{
  color:#fff;
  background-color: var(--primary-color);
}

/* ------------------------------------- Edit Member ----------------------------------------------------- */
.editMember{
  width:99vw;
  display: flex;
  background-color: var(--bgcolor);
}

.editMember .editMemberContainer{
  margin-top: 1.5rem;
  margin-left:2rem;
  padding: 1.5rem;
  background-color:#fff;
  width: 70vw;
  height:fit-content;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.editMemberContainer form .formInput{
  width:90%;
  padding:1rem;
}

.editMemberContainer form .formInput label{
  padding-bottom: 0.4rem;
}

.editMemberContainer form .formInput input, select{
  width: 100%;
  padding:0.3rem;
  border-radius: 3px;
  border: 0.1rem solid #b5b3b3;
}

.editMemberContainer form button{
  margin: 1rem;
  padding: 0.6rem;
  width:10vw;
  border-radius: 0.5rem;
  cursor:pointer;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.editMemberContainer form button:hover{
  color:#fff;
  background-color: var(--primary-color);
}

/* =========================================================== Jobs =========================================================== */
.jobs{
  background-color: var(--bgcolor);
  display: flex;
  margin:0;
  padding:0;
}

.allJobs{
  display: flex;
  flex:4;
  background-color: #fff;
}

.allJobs .jobsTitle{
  width:100%;
  font-size:2.2rem;
  margin-bottom:1rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.allJobs .jobsTitle .addJobBtn{
  text-decoration: none;
  color:green;
  font-size:1rem;
  font-weight: 400;
  border:1px solid green;
  padding: 5px;
  border-radius: 5px;
  cursor:pointer;
  display: flex;
}

.allJobs .jobTitle .addJobBtn .materialIcon{
  font-size: 1rem;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
  align-items: center;
  justify-content: center;
}

.allJobs .jobsTitle .addJobBtn:hover{
  color:#fff;
  background-color:green;
}

.allJobs .jobsTable{
  width:75vw;
  margin: 2rem;
}

.allJobs .cellAction{
  display: flex;
  align-items: center;
  gap:1.5rem;
  width: 20rem;
  padding:0.7rem 0rem;
}

.allJobs .cellAction .viewButton{
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--view-btn-color);
  border: 1px solid var(--view-btn-color);
  cursor:pointer;  
}

.allJobs .cellAction .viewButton:hover{
  border: 0.1rem solid var(--view-btn-color) !important;
  color:#fff !important;
  background-color: var(--view-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.allJobs .cellAction .updateButton{
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--update-btn-color);
  border: 1px solid var(--update-btn-color);
  cursor:pointer;  
}

.allJobs .cellAction .updateButton:hover{
  border: 0.1rem solid var(--update-btn-color) !important;
  color:#fff !important;
  background-color: var(--update-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.allJobs .cellAction .deleteButton{
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--delete-btn-color);
  border: 1px solid var(--delete-btn-color);
  cursor:pointer;
}

.allJobs .cellAction .deleteButton:hover{
  border: 0.1rem solid var(--delete-btn-color) !important;
  color:#fff !important;
  background-color: var(--delete-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

/* ------------------------------------- Add Job ----------------------------------------------------- */
.addJob{
  width:99vw;
  display: flex;
  background-color: var(--bgcolor);
}

.addJob .addJobContainer{
  margin-top: 1.5rem;
  margin-left:2rem;
  padding: 1.5rem;
  background-color:#fff;
  width: 70vw;
  height:fit-content;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.addJobContainer form .formInput{
  width:90%;
  padding:1rem;
}

.addJobContainer form .formInput label{
  padding-bottom: 0.4rem;
}

.addJobContainer form .formInput input, select{
  width: 100%;
  padding:0.3rem;
  border-radius: 3px;
  border: 0.1rem solid #b5b3b3;
}

.addJobContainer form button{
  margin: 1rem;
  padding: 0.6rem;
  width:10vw;
  border-radius: 0.5rem;
  cursor:pointer;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.addJobContainer form button:hover{
  color:#fff;
  background-color: var(--primary-color);
}

/* ----------------------------------- View Job ------------------------------------------------------ */
.viewJob{
  display: flex;
  flex:4;
  background-color: var(--bgcolor);
}

.viewJob .jobDetails{
  margin-top: 2rem;
  margin-left: 2rem;
  padding: 1.5rem;
  width:75vw;
  height:fit-content;
  background-color:white !important;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.viewJob .jobDetails .strong{
  width:25%;
  font-weight:500;
  font-size:1.3rem;
  padding-top:0.5rem;
}

.viewJob .jobDetails .content{
  font-size:1.1rem;
  text-align: justify;
}

.viewJob .jobDetails .actionBtns{
  display: flex;
  align-items: center;
  gap:1.5rem;
  width: 30%;
  padding-top: 1rem;
}

.viewJob .jobDetails .backButton{
  padding: 0.7rem;
  width:fit-content;
  border-radius: 5px;
  color: var(--view-btn-color);
  border: 1px solid var(--view-btn-color);
  cursor:pointer;
}

.viewJob .jobDetails .backButton:hover{
  border: 0.1rem solid var(--view-btn-color) !important;
  color:#fff !important;
  background-color: var(--view-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.viewJob .jobDetails .updateButton{
  padding: 0.7rem;
  border-radius: 5px;
  width:fit-content;
  color: var(--update-btn-color);
  border: 1px solid var(--update-btn-color);
  cursor:pointer;  
}

.viewJob .jobDetails .updateButton:hover{
  border: 0.1rem solid var(--update-btn-color) !important;
  color:#fff !important;
  background-color: var(--update-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

/* ------------------------------------- Edit Publication ----------------------------------------------------- */
.editJob{
  width:99vw;
  display: flex;
  background-color: var(--bgcolor);
}

.editJob .editJobContainer{
  margin-top: 1.5rem;
  margin-left:2rem;
  padding: 1.5rem;
  background-color:#fff;
  width: 70vw;
  height:fit-content;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.editJobContainer form .formInput{
  width:90%;
  padding:1rem;
}

.editJobContainer form .formInput label{
  padding-bottom: 0.4rem;
}

.editJobContainer form .formInput input, textarea{
  width: 100%;
  padding:0.3rem;
  border-radius: 3px;
  border: 0.1rem solid #b5b3b3;
}

.editJobContainer form button{
  margin: 1rem;
  padding: 0.6rem;
  width:10vw;
  border-radius: 0.5rem;
  cursor:pointer;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.editJobContainer form button:hover{
  color:#fff;
  background-color: var(--primary-color);
}

/* =========================================================== Users =========================================================== */
.users{
  background-color: var(--bgcolor);
  display: flex;
  margin:0;
  padding:0;
}

.allUsers{
  display: flex;
  flex:4;
  background-color: #fff;
}

.allUsers .usersTitle{
  width:100%;
  font-size:2.2rem;
  margin-bottom:1rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.allUsers .usersTitle .addUserBtn{
  text-decoration: none;
  color:green;
  font-size:1rem;
  font-weight: 400;
  border:1px solid green;
  padding: 5px;
  border-radius: 5px;
  cursor:pointer;
  display: flex;
}

.allUsers .userTitle .addUserBtn .materialIcon{
  font-size: 1rem;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
  align-items: center;
  justify-content: center;
}

.allUsers .usersTitle .addUserBtn:hover{
  color:#fff;
  background-color:green;
}

.allUsers .usersTable{
  width:75vw;
  margin: 2rem;
}

.allUsers .usersTable .MuiDataGrid-row{
  height: 2.5rem;
}
/* ------------------------------------- Add User ----------------------------------------------------- */
.addUser{
  width:99vw;
  display: flex;
  background-color: var(--bgcolor);
}

.addUser .addUserContainer{
  margin-top: 1.5rem;
  margin-left:2rem;
  padding: 1.5rem;
  background-color:#fff;
  width: 70vw;
  height:fit-content;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.addUserContainer form .formInput{
  width:90%;
  padding:1rem;
}

.addUserContainer form .formInput label{
  padding-bottom: 0.4rem;
}

.addUserContainer form .formInput input, select{
  width: 100%;
  padding:0.3rem;
  border-radius: 3px;
  border: 0.1rem solid #b5b3b3;
}

.addUserContainer form button{
  margin: 1rem;
  padding: 0.6rem;
  width:10vw;
  border-radius: 0.5rem;
  cursor:pointer;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.addUserContainer form button:hover{
  color:#fff;
  background-color: var(--primary-color);
}

/* ======================================================= Profile ================================================================== */
.profile{
  width:99vw;
  display: flex;
  background-color: var(--bgcolor);
}

.profile .profileContainer{
  margin-top: 1.5rem;
  margin-left:2rem;
  background-color:#fff;
  width: 70vw;
  height:fit-content;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.profile .profileContainer h2{
  padding-left: 1rem;
  padding-top: 1.5rem;
}

.profile .profileContainer .strong{
  width:25%;
  font-weight:500;
  font-size:1.3rem;
  padding-left: 1rem;
  padding-top:0.5rem;
}

.profile .profileContainer .content{
  font-size:1.1rem;
  text-align: justify;
  padding-left: 1rem;
}

.profile .profileContainer .actionBtns{
  display: flex;
  align-items: center;
  gap:1.5rem;
  width: 30%;
  padding-top: 1rem;
  padding-left: 1rem;
}

.profile .profileContainer .updateButton{
  padding: 0.7rem;
  border-radius: 5px;
  width:fit-content;
  color: var(--update-btn-color);
  border: 1px solid var(--update-btn-color);
  cursor:pointer;  
}

.profile .profileContainer .updateButton:hover{
  border: 0.1rem solid var(--update-btn-color) !important;
  color:#fff !important;
  background-color: var(--update-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

/* -------------------------------------------------------------- Update Profile ------------------------------------------------------------------- */
.updateProfile{
  width:99vw;
  display: flex;
  background-color: var(--bgcolor);
}

.updateProfile .updateProfileContainer{
  margin-top: 1.5rem;
  margin-left:2rem;
  background-color:#fff;
  width: 70vw;
  height:fit-content;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.updateProfile .updateProfileContainer h2{
  padding-left: 1rem;
  padding-top: 1.5rem;
}

.updateProfileContainer form label{
  padding-top:0.8rem;
  padding-left: 0.1rem;
  margin-bottom:0;
}

.updateProfileContainer form .updateProfileBtn{
  margin: 1rem;
  padding: 0.6rem;
  width:7vw;
  border-radius: 0.5rem;
  cursor:pointer;
  color: var(--update-btn-color);
  background-color: #fff;
  border: 1px solid var(--update-btn-color);
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.updateProfileContainer form .updateProfileBtn:hover{
  color:#fff;
  background-color: var(--update-btn-color);
}

.updateProfileContainer form .cancelUpdateBtn{
  margin: 1rem;
  padding: 0.6rem;
  text-decoration: none;
  width:10vw !important;
  border-radius: 0.5rem;
  cursor:pointer;
  color: var(--text-color);
  background-color: #fff;
  border: 1px solid var(--text-color);
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.updateProfileContainer form .cancelUpdateBtn:hover{
  color:#fff;
  background-color: var(--text-color);
}

/* =========================================================== Queries =========================================================== */
.queries{
  background-color: var(--bgcolor);
  display: flex;
  margin:0;
  padding:0;
}

.allQueries{
  display: flex;
  flex:4;
  background-color: #fff;
}

.allQueries .queriesTitle{
  width:100%;
  font-size:2.2rem;
  margin-bottom:1rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.allQueries .queriesTable{
  width:75vw;
  margin: 2rem;
}

.allQueries .cellAction{
  display: flex;
  align-items: center;
  gap:1.5rem;
  width: 20rem;
  padding:0.7rem 0rem;
}

.allQueries .cellAction .updateButton{
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--update-btn-color);
  border: 1px solid var(--update-btn-color);
  cursor:pointer;  
}

.allQueries .cellAction .updateButton:hover{
  border: 0.1rem solid var(--update-btn-color) !important;
  color:#fff !important;
  background-color: var(--update-btn-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.allQueries .queriesTable .MuiDataGrid-row{
  height: 2.5rem;
}


/* ============================================================= Error Page ========================================================================= */
#notFound{
  position:relative;
  height:100vh;
  color:#000;
}

#notFound .notFound{
  position:absolute;
  left:50%;
  top:40%;
  -webkit-transform:translate(-50%,-50%);
  -ms-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
  line-height:1.3;
  text-align: center;
  padding:0 1.5rem 0 1.5rem;
}

#notFound .notFound p{
  font-size: 1.3rem;
  font-weight:400;
  margin-top:-1.5rem;
}

#notFound .notFound a{
  font-size:1.2rem;
  text-decoration: none;
  background-color: var(--primary-color);
  display: inline-block;
  padding:1rem 2rem;
  border: 2px solid transparent;
  border-radius: 4rem;
  color:#fff;
  font-weight: 400;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

#notFound .notFound a:hover{
  border: 0.1rem solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

















.dashboard .card{
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
  border-radius: 0.9rem;
  padding: 0.5rem;
}

.dashboard .update-btn{
  margin-top:1.5rem;
  height:3rem;
  color:#fff;
  text-align: center;
  justify-content: center;
  display:flex;
  align-items: center;
  font-size: 1rem;
  padding:5px 0;
  background-color: var(--primary-color);
  border-radius: 0.6rem!important;
  border: 0.1rem solid var(--primary-color);
}

.dashboard .update-btn:hover{
  border: 0.1rem solid var(--primary-color) !important;
  color:#fff !important;
  background-color: var(--primary-color) !important;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.dashboard a{
  text-decoration: none;
  color: var(--primary-color);
}

.dashboard a:hover{
  text-decoration: none;
  color: var(--primary-color);
}
